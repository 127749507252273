import { CheckIcon, CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Link,
  ListItem,
  Text,
  Textarea,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { MenuOption } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { editorStateToPlainText } from "@plotify-ops/shared/dist/editorStateToPlainText";
import { compile } from "@plotify-ops/shared/dist/expressions";
import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { Controller, FieldErrors, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { MenuOptionsList, TextEditor, TypeaheadPluginProps } from "../";
import {
  CreateExpression,
  useGetExpressionParams,
  useGetPlotDataPoints,
} from "../../data";
import { FormContainer } from "../../page/FormContainer";
import { getPaths } from "../../utils/getPaths";
import { ExpressionListContext } from "../../providers/ExpressionContext";

const expressionSchema = z.string().superRefine((val, ctx) => {
  try {
    const plainText = editorStateToPlainText(val);
    compile({ plainText });
    return true;
  } catch (error) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Expression Error: ${
        error instanceof Error ? error.message : `${error}`
      }`,
    });
  }
});

const validationSchema = z.object({
  name: z.string(),
  expression: expressionSchema,
  description: z.string(),
});

const menuRenderFn: TypeaheadPluginProps["menuRenderFn"] = (
  domRef,
  { options, ...rest },
) => {
  if (!domRef.current || !options.length) return null;

  const { selectedIndex, setHighlightedIndex, selectOptionAndCleanUp } = rest;

  return createPortal(
    <MenuOptionsList
      selectedIndex={selectedIndex}
      onClick={(index) => {
        setHighlightedIndex(index);
        selectOptionAndCleanUp(options[index]);
      }}
      onHighlight={(index) => {
        setHighlightedIndex(index);
      }}
      options={options}
    />,
    domRef.current,
  );
};

export type ExpressionFormProps = {
  onSubmit?: (data: CreateExpression) => void;
  onCancel?: () => void;
  onChange?: (
    data: Partial<CreateExpression>,
    errors: FieldErrors<CreateExpression>,
  ) => void;
  defaultValues?: Partial<CreateExpression>;
  disabled?: boolean;
  title?: string;
  mode?: "edit" | "create";
};

export const ExpressionForm = ({
  onSubmit,
  defaultValues,
  disabled = false,
  title = "Expression Form",
  mode = "create",
  onCancel,
  onChange,
}: ExpressionFormProps) => {
  const [typeaheadQuery, setTypeaheadQuery] = useState<string | null>(null);

  const expressionList = React.useContext(ExpressionListContext);

  const { fetch: fetchMatchingExpressionProps } = useGetExpressionParams({
    query: typeaheadQuery,
  });

  const { data: dataPoints } = useGetPlotDataPoints();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateExpression>({
    mode: "onBlur",
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const changedValues = useWatch({ control });

  useEffect(() => {
    onChange && onChange(changedValues, errors);
    // @note: react-hooks-form error object ref does not change
    //        despite the errors contained do
  }, [onChange, changedValues, JSON.stringify(errors)]);

  const expressionProperties = useMemo(() => {
    return typeaheadQuery?.startsWith("e.")
      ? expressionList
          ?.filter(
            (path) =>
              typeaheadQuery &&
              path.name.toLowerCase().indexOf(typeaheadQuery.substring(2)) ===
                0,
          )
          .map((path) => ({ name: path.name }))
          .slice(0, 10)
      : getPaths(dataPoints, "p.")
          .sort()
          .filter(
            (path) => typeaheadQuery && path.indexOf(typeaheadQuery) === 0,
          )
          .map((path) => ({ name: path }))
          .slice(0, 10);
  }, [dataPoints, typeaheadQuery, expressionList]);

  useEffect(() => {
    fetchMatchingExpressionProps();
  }, [fetchMatchingExpressionProps]);

  const options = useMemo(() => {
    if (!typeaheadQuery || !expressionProperties) return [];

    return expressionProperties.map(
      (option) => new MenuOption(`${option.name}`),
    );
  }, [typeaheadQuery, expressionProperties]);

  const typeaheadConfig = useMemo(() => {
    return {
      triggers: ["p.", "e."],
      options,
      onQueryChange: setTypeaheadQuery,
      menuRenderFn,
    };
  }, [options, setTypeaheadQuery]);

  const onFormSubmit = useCallback<FormEventHandler>(
    (event) => {
      if (disabled) return;
      return onSubmit && handleSubmit(onSubmit)(event);
    },
    [disabled, onSubmit, handleSubmit],
  );

  const isEditMode = mode === "edit";

  return (
    <FormContainer title={title} onSubmit={onFormSubmit}>
      {!isEditMode && (
        <FormControl mb="16px">
          <FormLabel>Name</FormLabel>
          <Input {...register("name")} />
        </FormControl>
      )}

      <Controller
        control={control}
        name="expression"
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const isError = !!error;

          return (
            <FormControl mb="16px" isInvalid={isError}>
              <FormLabel>Calculation</FormLabel>
              <TextEditor
                typeahead={typeaheadConfig}
                onChange={onChange}
                initialState={value}
              />
              {isError && (
                <FormErrorMessage>
                  {error.message || "unknown"}
                </FormErrorMessage>
              )}
            </FormControl>
          );
        }}
      />

      <FormControl mb="16px">
        <FormLabel>Description</FormLabel>
        <Textarea
          resize={"none"}
          placeholder="Enter the Description for the Expression"
          {...register("description")}
        />
      </FormControl>

      <FormControl>
        <FormHelperText>
          <VStack spacing="8px" alignItems={"flex-start"}>
            <Text>Things to note:</Text>
            <UnorderedList spacing={"4px"}>
              <ListItem>To access a plot property type "p."</ListItem>
              <ListItem>
                You can refer to other expressions by their name e.g
                "anotherCalc + 100"
              </ListItem>
              <ListItem>
                You can use{" "}
                <Link
                  color="teal.500"
                  isExternal
                  href="https://mathjs.org/docs/expressions/syntax.html#multiline-expressions"
                >
                  multi line
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </ListItem>
              <ListItem>
                You can{" "}
                <Link
                  color="teal.500"
                  isExternal
                  href="https://mathjs.org/docs/expressions/syntax.html#comments"
                >
                  comment
                  <ExternalLinkIcon mx="2px" />
                </Link>{" "}
                out anything using "#" to preceed it{" "}
              </ListItem>
              <ListItem>
                This field is powered by{" "}
                <Link
                  color="teal.500"
                  isExternal
                  href="https://mathjs.org/docs/expressions/syntax.html"
                >
                  Math.js
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </ListItem>
            </UnorderedList>
          </VStack>
        </FormHelperText>
      </FormControl>

      <HStack justify={"space-between"}>
        <Button
          disabled={disabled}
          leftIcon={<CheckIcon />}
          colorScheme="green"
          type="submit"
        >
          Save
        </Button>

        {mode === "create" && (
          <Button
            disabled={disabled}
            leftIcon={<CloseIcon />}
            colorScheme="red"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}

        {mode === "edit" && (
          <Button
            disabled={disabled}
            leftIcon={<CloseIcon />}
            variant="outline"
            onClick={onCancel}
          >
            Close
          </Button>
        )}
      </HStack>
    </FormContainer>
  );
};
