import { isObjectLiteral, CustomIsNotObjectLiteral } from "./is-object-literal";

export type FlatObjectOptions = {
  delimiter?: string;
  customIsNotObjectLiteral?: CustomIsNotObjectLiteral;
};
// eslint-disable-next-line @typescript-eslint/ban-types
export const flatObject = (obj: Object, options?: FlatObjectOptions) => {
  const { delimiter, customIsNotObjectLiteral } = {
    ...{
      delimiter: ".",
      customIsNotObjectLiteral: () => false,
    },
    ...options,
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  const flatten = (obj: Object, key = "") => {
    if (!isObjectLiteral(obj, customIsNotObjectLiteral)) {
      if (key) {
        return { [key]: obj };
      }
      return obj;
    }

    const prefix = `${key}${key ? delimiter : ""}`;
    // eslint-disable-next-line @typescript-eslint/ban-types
    return Object.entries(obj).reduce<Object>((map, [key, value]): Object => {
      const path = `${prefix}${key}`;

      if (isObjectLiteral(value, customIsNotObjectLiteral)) {
        return {
          ...map,
          ...flatten(value, path),
        };
      }

      if (Array.isArray(value)) {
        return {
          ...map,
          ...value.reduce((map, value, index) => {
            return {
              ...map,
              ...flatten(value, `${path}${delimiter}${index}`),
            };
          }, {}),
        };
      }

      return {
        ...map,
        [path]: value,
      };
    }, {});
  };

  return flatten(obj);
};
