import { toast } from "react-toastify";
import { CreateExpression, useExpressionCreateService } from "../data";
import { ExpressionForm } from "../ui";
import { useExpressionNavigation } from "./Expressions";

export const ExpressionCreate = () => {
  const { close, gotToEdit } = useExpressionNavigation();

  const { createExpression, loading } = useExpressionCreateService();

  const onSubmit = async (payload: CreateExpression) => {
    try {
      const { data } = await createExpression(payload);
      toast("Saved!");
      gotToEdit(data.id);
    } catch (error: any) {
      toast(`Error: ${error?.response?.data?.message}`);
      console.error("Expression did not save: ", error);
    }
  };

  return (
    <ExpressionForm
      disabled={loading}
      title="Create an Expression"
      onSubmit={onSubmit}
      onCancel={close}
    />
  );
};
