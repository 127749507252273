import { Select, VStack } from "@chakra-ui/react";
import { FinTemplate, Plot } from "../data";
import { ChangeEventHandler, useCallback, useMemo } from "react";
import { Column, Table } from "./Table";

export type PlotFinancialFormProps = {
  plot: Plot;
  templates: FinTemplate[];
  onClose: () => void;
  onChange: (id: string | null) => void;
  isLoading?: boolean;
};

const fields: Column<{ label: string; value: number }>[] = ["label", "value"];

export const PlotFinancialForm = ({
  plot,
  templates,
  onChange,
  isLoading,
}: PlotFinancialFormProps) => {
  const financials = useMemo(() => {
    return Object.entries(plot.calculated || {}).map(([label, value]) => ({
      label,
      value,
    }));
  }, [plot]);

  const onSelectTemplate = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      onChange(e.target.value || null);
    },
    [onChange],
  );
  return (
    <VStack p="20px">
      <Select
        placeholder="Select a template"
        defaultValue={plot.finTemplateId}
        onChange={onSelectTemplate}
      >
        {templates.map((tmpl) => {
          <option>Unselected</option>;
          return (
            <option key={tmpl.id} value={tmpl.id}>
              {tmpl.name}
            </option>
          );
        })}
      </Select>

      {isLoading && <h4>calculating ...</h4>}
      {!isLoading && <Table fields={fields} data={financials} />}
    </VStack>
  );
};
