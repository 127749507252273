import {
  $applyNodeReplacement,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
  TextNode,
} from "lexical";

export class ExpressionNode extends TextNode {
  static getType(): string {
    return "expression";
  }

  static clone(node: ExpressionNode): ExpressionNode {
    return new ExpressionNode(node.__text, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    // element.style.border
    element.style.color = "blue";
    element.style.fontWeight = "bold";
    return element;
  }

  updateDOM(
    prevNode: ExpressionNode,
    dom: HTMLElement,
    config: EditorConfig,
  ): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    return isUpdated;
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: ExpressionNode.getType(),
    };
  }
}

export const $createExpressionNode = (term: string): ExpressionNode => {
  const exNode = new ExpressionNode(term);
  exNode.setMode("segmented").toggleDirectionless();
  return $applyNodeReplacement(exNode);
};

export const $isExpressionNode = (
  node: LexicalNode | null | undefined,
): node is ExpressionNode => {
  return node instanceof ExpressionNode;
};
