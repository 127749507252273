"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$isExpressionNode = exports.$createExpressionNode = exports.ExpressionNode = void 0;
const lexical_1 = require("lexical");
class ExpressionNode extends lexical_1.TextNode {
    static getType() {
        return 'expression';
    }
    static clone(node) {
        return new ExpressionNode(node.__text, node.__key);
    }
    createDOM(config) {
        const element = super.createDOM(config);
        // element.style.border
        element.style.color = 'blue';
        element.style.fontWeight = 'bold';
        return element;
    }
    updateDOM(prevNode, dom, config) {
        const isUpdated = super.updateDOM(prevNode, dom, config);
        return isUpdated;
    }
    isTextEntity() {
        return true;
    }
    canInsertTextBefore() {
        return false;
    }
    canInsertTextAfter() {
        return false;
    }
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: ExpressionNode.getType() });
    }
}
exports.ExpressionNode = ExpressionNode;
const $createExpressionNode = (term) => {
    const exNode = new ExpressionNode(term);
    exNode.setMode('segmented').toggleDirectionless();
    return (0, lexical_1.$applyNodeReplacement)(exNode);
};
exports.$createExpressionNode = $createExpressionNode;
const $isExpressionNode = (node) => {
    return node instanceof ExpressionNode;
};
exports.$isExpressionNode = $isExpressionNode;
