import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { enableDevMode, isDevMode } from "./utils/misc";

if (process.env["REACT_APP_PLOTIFY_DEV"] === "true") {
  (window as unknown as { enableDevMode: typeof enableDevMode }).enableDevMode =
    enableDevMode;

  (window as unknown as { isDevMode: typeof isDevMode }).isDevMode = isDevMode;
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
