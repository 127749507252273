import { useCallback, useEffect, useMemo } from "react";
import {
  Outlet,
  useNavigate,
  useOutlet,
  useOutletContext,
} from "react-router-dom";
import { SideDrawer } from "../ui/SideDrawer";

type AsideFormContext = {
  close: () => void;
};

type AsideFormProviderProps = {
  onClose?: () => void;
  context?: any;
};

export const AsideFormProvider = ({
  onClose,
  context,
}: AsideFormProviderProps) => {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const isOpen = useMemo(() => !!outlet, [outlet]);

  const _onClose = useCallback(() => {
    onClose && onClose();
    navigate(-1);
  }, [navigate, onClose]);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [outlet, isOpen, onClose]);

  return (
    <SideDrawer isOpen={isOpen} onClose={_onClose}>
      <Outlet
        context={
          {
            ...context,
            close: _onClose,
          } satisfies AsideFormContext
        }
      />
    </SideDrawer>
  );
};

export const useAsideFormContext = () => {
  return useOutletContext<AsideFormContext>();
};
