import { ColumnConfig, CustomColumnConfig } from "../ui";

export const isObject = (data: unknown): data is Record<string, unknown> => {
  return typeof data === "object";
};

export const isCustomColumnConfig = <T,>(
  config: unknown,
): config is CustomColumnConfig<T> => {
  return (
    typeof config === "object" &&
    typeof (config as unknown as CustomColumnConfig<T>).render === "function"
  );
};

export const isColumnConfig = <T,>(
  config: unknown,
): config is ColumnConfig<T> => {
  return (
    typeof config === "object" &&
    typeof (config as unknown as ColumnConfig<T>).name === "string"
  );
};
