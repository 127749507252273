import { DownloadIcon } from "@chakra-ui/icons";
import { Link, useDisclosure } from "@chakra-ui/react";
import { kebabCase } from "change-case";
import moment from "moment";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useIPA, useIPAProps, usePurchaseRequestsService } from "../data";
import { PurchaseRequest } from "../data/types";
import { Header } from "../ui";
import { Action, Column, Table } from "../ui/Table";
import { ValidationErrors, isValidationError } from "../ui/ValidationErrors";
import { formatPrice } from "../utils/formatters";
import { PurchaseRequestForm } from "./PurchaseRequestForm";
import { SideDrawer } from "../ui/SideDrawer";

const getId = (record: PurchaseRequest) => `reqpur-${record.id}`;
const fields: Column<PurchaseRequest>[] = [
  {
    name: "date",
    title: "Date",
    format: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
  },
  { name: "plotName", title: "Plot" },
  { name: "sellerName", title: "Seller" },
  { name: "investorName", title: "Buyer" },
  {
    name: "plotPrice",
    title: "Plot Price",
    format: (price, data) => (price ? formatPrice(price, data.currency) : "-"),
  },
  {
    title: "IPA",
    name: "ipaUrl",
    format: (value, data) => (
      <>
        {value ? (
          <Link
            target="_blank"
            href={value}
            download={`${kebabCase(
              `${moment(data.date).format("YYYYMMDDHHmm")} ${data.plotName}${
                data.sellerName
              }`,
            )}.pdf`}
          >
            <DownloadIcon />
          </Link>
        ) : (
          ""
        )}
      </>
    ),
  },
];

export const PurchaseRequests = () => {
  const { purchaseRequests, refetch } = usePurchaseRequestsService();

  const handleIPASuccess = useCallback<
    Required<useIPAProps<PurchaseRequest>>["onSuccess"]
  >(
    (downloadURL, ref) => {
      toast.success(
        <>
          IPA generated for{" "}
          <a href={downloadURL} rel="noreferrer" target="_blank">
            {ref.meta?.plotName}
          </a>
          .
        </>,
      );
      refetch();
    },
    [refetch],
  );

  const handleIPAError = useCallback<
    Required<useIPAProps<PurchaseRequest>>["onError"]
  >((error, ref) => {
    const title = `IPA Failed for "${ref.meta?.plotName}"`;
    toast.error(
      isValidationError(error) ? (
        <ValidationErrors title={title} error={error} />
      ) : (
        `${title}${
          typeof error === "object" && error !== null
            ? `: ${(error as { message: any }).message}`
            : ""
        }`
      ),
    );
  }, []);

  const { generateIPA, loading } = useIPA<PurchaseRequest>({
    onSuccess: handleIPASuccess,
    onError: handleIPAError,
  });

  const [selectedPurchaseRequest, setSelectedPurchaseRequest] = useState<
    PurchaseRequest | undefined
  >();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadPDF = useCallback(
    (data: PurchaseRequest) => {
      if (loading) return;
      generateIPA({ id: data.id, meta: data });
    },
    [generateIPA, loading],
  );

  const openEditor = useCallback(
    (data: PurchaseRequest) => {
      setSelectedPurchaseRequest(data);
      onOpen();
    },
    [onOpen],
  );

  return (
    <>
      <Header title="Purchase Requests" />
      {!purchaseRequests?.length && <h2>No records</h2>}
      {purchaseRequests && (
        <Table getId={getId} fields={fields} data={purchaseRequests}>
          <Action<PurchaseRequest>
            onClick={downloadPDF}
            label={"Generate PDF"}
          />
          <Action<PurchaseRequest> onClick={openEditor} label={"Edit Meta"} />
        </Table>
      )}
      <SideDrawer
        isOpen={!!(isOpen && selectedPurchaseRequest)}
        onClose={onClose}
      >
        {selectedPurchaseRequest && (
          <PurchaseRequestForm
            id={selectedPurchaseRequest.id}
            onClose={onClose}
          />
        )}
      </SideDrawer>
    </>
  );
};
