import { AxiosInstance } from "axios";
import { useCallback } from "react";
import { usePlotifyService } from "../providers/PlotifyService";
import { FinTemplate } from "./types";

export type useCreateFinTemplateOptions = {
  lazy: boolean;
};

export const useCreateFinTemplate = ({ lazy }: useCreateFinTemplateOptions) => {
  const fetch = useCallback((client: AxiosInstance, data: FinTemplate) => {
    return client.post<FinTemplate>("/fin-templates", data);
  }, []);

  const { refetch, ...rest } = usePlotifyService<
    FinTemplate,
    [data: FinTemplate]
  >({
    lazy,
    fetch,
  });

  return {
    ...rest,
    create: refetch,
  };
};

export const useGetFinTemplate = (id: string) => {
  const fetch = useCallback((client: AxiosInstance, id: string) => {
    return client.get<FinTemplate>(`/fin-templates/${id}`);
  }, []);

  const { refetch, ...rest } = usePlotifyService<FinTemplate, [id: string]>({
    lazy: false,
    fetch,
    initialFetchArgs: [id],
  });

  return {
    ...rest,
    getFinTemplate: refetch,
  };
};

export const useGetFinTemplates = () => {
  const fetch = useCallback((client: AxiosInstance) => {
    return client.get<FinTemplate[]>("/fin-templates/");
  }, []);

  return usePlotifyService<FinTemplate[]>({
    lazy: false,
    fetch,
  });
};
