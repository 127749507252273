import { createContext, ReactNode, useMemo, useContext } from "react";

export type RecordContextValues<T = any> = {
  data: T;
};

export const RecordContext = createContext<RecordContextValues>({
  data: {},
});

export type RecordProviderProps<T> = {
  data: T;
  children: ReactNode;
};

export const RecordProvider = <T,>({
  children,
  data,
}: RecordProviderProps<T>) => {
  const context = useMemo<RecordContextValues<T>>(
    () => ({
      data,
    }),
    [data],
  );

  return (
    <RecordContext.Provider value={context}>{children}</RecordContext.Provider>
  );
};

export const useRecord = <T,>() => {
  return useContext<RecordContextValues<T>>(RecordContext);
};
