import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorState,
} from "lexical";
import { useCallback, useEffect, useState } from "react";
import { styled as s } from "styled-components";
import { ExpressionNode } from "./ExpressionNode";
import { TypeaheadPlugin, TypeaheadPluginProps } from "./TypeaheadPlugin";

export default function AutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

const EditorContainer = s.div<{ $focused: boolean }>`
  background: white;
  padding: 20px;
  position: relative;
  min-width: 600px;
  text-align: left;
  border-radius: 8px;
  border: 2px solid ${({ $focused }) => ($focused ? "#3182ce" : "#f6f6f6")};
`;

const EditorInnerContainer = s.div`
  position: relative;
  min-width: 600px;
  text-align: left;
`;

const EditorContentEditable = s(ContentEditable)`
  position: relative;
  z-index: 999;
  &:focus {
    outline: 0px solid transparent;
  }

`;

const Placeholder = s.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.5
`;

const editorConfig = {
  namespace: "Editor",
  theme: {
    ltr: "ltr",
    rtl: "rtl",
    placeholder: "editor-placeholder",
    paragraph: "editor-paragraph",
  },
  onError(error: Error) {
    throw error;
  },
  nodes: [ExpressionNode],
};

export type TextEditorProps = {
  onChange?: (value: string) => void;
  typeahead?: TypeaheadPluginProps;
  initialState?: null | string | EditorState;
  onFocus?: () => void;
  onBlur?: () => void;
};

export type InitialStateProps = {
  state?: null | string | EditorState;
};

export const InitialState = ({ state }: InitialStateProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!state) return;
    editor.update(() => {
      try {
        const editorState = editor.parseEditorState(state as string);
        editor.setEditorState(editorState);
      } catch (error) {
        const paragraph = $createParagraphNode();
        const textNode = $createTextNode(state as string);

        paragraph.append(textNode);

        $getRoot().clear().append(paragraph);
      }
    });
  }, [editor]);

  return null;
};

export const TextEditor = ({
  typeahead,
  onChange,
  initialState,
  onFocus,
  onBlur,
}: TextEditorProps) => {
  const [focused, setFocused] = useState(false);

  const getTextContent = useCallback(
    (editorState: EditorState) => {
      const state = editorState.toJSON();
      onChange && onChange(JSON.stringify(state));
    },
    [onChange],
  );

  // const initialEditorConfig = useMemo(
  //   () => ({
  //     ...editorConfig,
  //     // editorState: initialState,
  //   }),
  //   [initialState]
  // );

  const _onFocus = useCallback(() => {
    setFocused(true);
    onFocus && onFocus();
  }, [onFocus]);

  const _onBlur = useCallback(() => {
    setFocused(false);
    onBlur && onBlur();
  }, [onBlur]);

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <>
        <InitialState state={initialState} />
        <EditorContainer $focused={focused}>
          <EditorInnerContainer className="editor-container">
            <PlainTextPlugin
              contentEditable={
                <EditorContentEditable
                  className="editor-input"
                  onFocus={_onFocus}
                  onBlur={_onBlur}
                />
              }
              placeholder={
                <Placeholder>
                  Enter your expression ... e.g{" "}
                  <i>p.plotPrice - c.investmentAmount</i>
                </Placeholder>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            {typeahead && <TypeaheadPlugin {...typeahead} />}
          </EditorInnerContainer>
        </EditorContainer>
      </>
      <OnChangePlugin onChange={getTextContent} />
    </LexicalComposer>
  );
};
