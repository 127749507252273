import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { editorStateToPlainText } from "@plotify-ops/shared/dist/editorStateToPlainText";
import { useCallback, useMemo, useState } from "react";
import { FieldErrors } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreateExpression,
  useExpression,
  useExpressionUpdate,
  useGetExpressionVersions,
} from "../data";
import { generatePlotData } from "../data/generate/plot";
import { useGetPlot } from "../data/plots";
import { ExpressionForm } from "../ui";
import { ExpressionPreview } from "./ExpressionPreview";
import { useExpressionNavigation } from "./Expressions";
import { ExpressionVersions } from "./ExpressionVersions";

export const ExpressionEdit = () => {
  const { close } = useExpressionNavigation();
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();

  const { expression, loading: isFetching, refetch } = useExpression({ id });
  const { updateExpression, loading: isSaving } = useExpressionUpdate();
  const { expressionVersions, getExpressionVersions } =
    useGetExpressionVersions({ id });

  const {
    plot: fetchedPlot,
    loading: isFetchingPlot,
    getPlot,
    reset: resetFetchedPlotData,
  } = useGetPlot({ lazy: true });

  const [updatedExpression, setUpdatedExpression] = useState({
    name: "<undefined>",
    expression: '"<undefined>"',
    description: '"<undefined>"',
  });

  const onSubmit = async (payload: CreateExpression) => {
    try {
      const { data } = await updateExpression(id, payload);
      refetch(data.id);
      getExpressionVersions(data.id);
      navigate(`/expressions/edit/${data.id}`, { replace: true });
      toast("Saved!");
    } catch (error) {
      console.error("Expression did not save: ", error);
    }
  };

  const plot = useMemo(() => {
    return fetchedPlot || generatePlotData();
  }, [fetchedPlot]);

  const handleExpressionChange = useCallback(
    (
      expression: Partial<CreateExpression>,
      errors: FieldErrors<CreateExpression>,
    ) => {
      const expressionError = errors.expression?.message;

      setUpdatedExpression({
        name: errors.name?.message || expression.name || "<undefined>",
        expression: expressionError
          ? `"${errors.expression?.message}"`
          : editorStateToPlainText(expression.expression || ""),
        description:
          errors.description?.message ||
          expression.description ||
          "<undefined>",
      });
    },
    [],
  );

  const fetchPlotData = useCallback(
    (plotName: string) => {
      getPlot({ plotname: plotName });
    },
    [getPlot],
  );

  const resetPlotData = useCallback(() => {
    resetFetchedPlotData();
  }, [resetFetchedPlotData]);

  const versions = useMemo(() => {
    return expressionVersions || [];
  }, [expressionVersions]);

  if (!expression) {
    <Heading>Loading ...</Heading>;
  }

  return (
    <>
      <Tabs display={"flex"} flexDir={"column"} minHeight="100%">
        <TabList>
          <Tab>Form</Tab>
          <Tab>Preview</Tab>
          <Tab>Versions ({expression?.versions.length})</Tab>
        </TabList>

        <TabPanels as={Flex} flex={1}>
          <TabPanel>
            {expression && (
              <ExpressionForm
                defaultValues={expression}
                disabled={isFetching || isSaving}
                title={`Edit Expression: ${expression.name}`}
                onSubmit={onSubmit}
                onCancel={close}
                mode="edit"
                onChange={handleExpressionChange}
              />
            )}
          </TabPanel>
          <TabPanel flex={1}>
            <ExpressionPreview
              name={updatedExpression.name}
              expression={updatedExpression.expression}
              plot={plot}
              onRequestPlotData={fetchPlotData}
              onResetPlotData={resetPlotData}
              isFetchingPlot={isFetchingPlot}
            />
          </TabPanel>
          <TabPanel flex={1}>
            <ExpressionVersions versions={versions} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
