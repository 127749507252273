import {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useDevMode } from "../utils/misc";
import { useAccessToken } from "./AccessTokenProvider";

type PlotifyServiceContextProps = {
  client: AxiosInstance;
};

const PlotifyServiceContext = createContext<PlotifyServiceContextProps>({
  client: {} as unknown as AxiosInstance,
});

export type PlotifyServiceProviderProps = {
  baseURL: string;
  children: ReactNode;
};

export const PlotifyServiceProvider = ({
  baseURL,
  children,
}: PlotifyServiceProviderProps) => {
  const { isDevMode } = useDevMode();
  const { accessToken } = useAccessToken();

  const client = useMemo(() => {
    const headers: {
      Authorization?: string;
      xdevMode?: string;
    } = {};

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    if (isDevMode) {
      headers.xdevMode = "true";
    }
    return axios.create({
      baseURL,
      headers,
    });
  }, [baseURL, accessToken, isDevMode]);

  const contextValue = useMemo(
    () => ({
      client,
    }),
    [client],
  );

  return (
    <PlotifyServiceContext.Provider value={contextValue}>
      {children}
    </PlotifyServiceContext.Provider>
  );
};

export type usePlotifyServiceProps<FetchArgs extends unknown[]> = {
  fetch: (
    client: AxiosInstance,
    ...fetchArgs: FetchArgs
  ) => Promise<AxiosResponse>;
  refetchOnClientChange?: boolean;
  lazy?: boolean;
  initialFetchArgs?: FetchArgs;
  supressError?: boolean;
};

export type usePlotifyServiceResponse<T, FetchArgs extends unknown[]> = {
  data?: T;
  error?: Error;
  loading: boolean;
  refetch: (...fetchArgs: FetchArgs) => Promise<AxiosResponse<T>>;
  reset: () => void;
};

export const usePlotifyService = <T, FetchArgs extends unknown[] = unknown[]>({
  fetch,
  refetchOnClientChange = true,
  lazy,
  initialFetchArgs,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}: usePlotifyServiceProps<FetchArgs>): usePlotifyServiceResponse<
  T,
  FetchArgs
> => {
  const { client } = useContext(PlotifyServiceContext);

  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setloading] = useState(false);
  const [isFetched, setFetched] = useState(false);

  const reset = useCallback(() => {
    setData(undefined);
    setError(undefined);
    setloading(false);
    setFetched(false);
  }, []);

  const fetchData = useCallback(
    async (...args: FetchArgs) => {
      setloading(true);
      try {
        const response = await fetch(client, ...args);
        setData(response.data);
        setloading(false);
        setError(undefined);
        return response;
      } catch (error) {
        setError(error as Error);
        setloading(false);
        throw error;
      }
    },
    [fetch, client],
  );

  const refetch = useCallback(
    (...args: FetchArgs) => {
      return fetchData(...args);
    },
    [fetchData],
  );

  useEffect(() => {
    if (refetchOnClientChange) {
      setFetched(false);
    }
  }, [refetchOnClientChange, client]);

  useEffect(() => {
    if (lazy) return;

    if (isFetched) return;

    setFetched(true);

    fetchData(...(initialFetchArgs || ([] as unknown as FetchArgs))).catch(
      (error: Error) => {
        console.error(`Fetch Data Error: ${error}`);
      },
    );
  }, [initialFetchArgs, lazy, isFetched, fetchData]);

  return { data, error, loading, refetch, reset };
};
