import { useEffect, useState, useCallback } from "react";

let DEV_MODE = false;

export const DEV_MOVE_EVENT_NAME = "dev.plotify.devMode";

export const enableDevMode = (enabled = true) => {
  DEV_MODE = enabled;
  window.dispatchEvent(new Event("dev.plotify.devMode"));
};

export const isDevMode = () => DEV_MODE;

export const useDevMode = () => {
  const [isDevMode, setDevMode] = useState(false);

  const handleStorage = useCallback(() => {
    setDevMode(DEV_MODE);
  }, []);

  useEffect(() => {
    window.addEventListener(DEV_MOVE_EVENT_NAME, handleStorage);
    return () => {
      window.removeEventListener(DEV_MOVE_EVENT_NAME, handleStorage);
    };
  }, [handleStorage]);

  return { isDevMode };
};
