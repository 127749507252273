import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FinTemplate, useGetExpressions } from "../data";

export type FinTemplateFormProps = {
  defaultValues?: FinTemplate;
  onClose?: () => void;
  onSubmit?: (data: FinTemplate) => void;
  editMode?: boolean;
};
export const FinTemplateForm = ({
  editMode,
  defaultValues,
  onClose,
  onSubmit,
}: FinTemplateFormProps) => {
  const { register, handleSubmit, control } = useForm<FinTemplate>({
    defaultValues,
  });

  const { data: expressions = [] } = useGetExpressions();

  const internalOnSubmit = useCallback(
    (data: FinTemplate) => {
      onSubmit && onSubmit(data);
    },
    [onSubmit],
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "expressions",
  });

  return (
    <form onSubmit={handleSubmit(internalOnSubmit)}>
      <VStack spacing="20px" p="24px" alignItems={"left"}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading>{editMode ? "Edit" : "Create"} Template</Heading>
          {onClose && (
            <Button colorScheme="red" onClick={onClose}>
              Close
            </Button>
          )}
        </HStack>

        <FormControl>
          <FormLabel>Template Name</FormLabel>
          <Input {...register("name")} />
        </FormControl>

        <HStack justifyContent="space-between" alignItems="center">
          <Button type="submit" colorScheme="green">
            Save
          </Button>
        </HStack>

        <Divider />

        <HStack justifyContent="space-between" alignItems="center">
          <Heading size="md">Expressions:</Heading>
          <Button
            colorScheme="blue"
            onClick={() => append({ displayName: "", expressionId: "" })}
          >
            Add
          </Button>
        </HStack>

        {fields.map((field, index) => {
          return (
            <HStack key={field.id}>
              <Input
                {...register(`expressions.${index}.displayName`)}
                placeholder="Set a display name"
              />

              <Controller
                control={control}
                name={`expressions.${index}.expressionId`}
                render={({ field }) => {
                  return (
                    <Select {...field} placeholder="Select an expression">
                      {expressions.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                  );
                }}
              />
              <Button colorScheme="red" onClick={() => remove(index)}>
                Delete
              </Button>
            </HStack>
          );
        })}
      </VStack>
    </form>
  );
};
