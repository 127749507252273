import { styled as s } from "styled-components";

export const Container = s.div<{ $stack?: "row" | "column" }>`
  display: flex;
  margin: 0 20px;
  flex-direction: row};
`;

export const Panel = s.div<{ $flex?: number }>`
  display: flex;
  padding: 20px;
  flex: 1;
`;
