import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  TypeaheadMenuPluginProps,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { TextNode } from "lexical";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { styled as s } from "styled-components";
import { $createExpressionNode } from "./ExpressionNode";

export const UITypeahedSuggested = s.div<{
  $offsetLeft: number;
  $offsetTop: number;
}>`
  position: relative;
  left: ${({ $offsetLeft }) => $offsetLeft}px;
  transform: translateY(-100%);
  opacity: 40%;
`;

export type TypeahedSuggestedProps<T extends MenuOption> = {
  option: T;
  anchorRef: HTMLElement;
};
export const TypeahedSuggested = <T extends MenuOption>({
  option,
  anchorRef,
}: TypeahedSuggestedProps<T>) => {
  const suggestedRef = useRef<HTMLDivElement>(null);
  const boundingRect = anchorRef.getBoundingClientRect();

  const [initialRect, setInitialRect] = useState<DOMRect>();

  useEffect(() => {
    if (!suggestedRef.current) return;
    if (initialRect) return;

    const currentRect = suggestedRef.current.getBoundingClientRect();

    setInitialRect(currentRect);
  }, [initialRect]);

  const { left, top } = useMemo(() => {
    if (!initialRect) return { left: 0, top: 0 };

    return {
      left: boundingRect.x - initialRect.x,
      top: boundingRect.y - initialRect.y,
    };
  }, [initialRect, boundingRect]);

  return (
    <UITypeahedSuggested $offsetLeft={left} $offsetTop={top} ref={suggestedRef}>
      {option.key}
    </UITypeahedSuggested>
  );
};

export type TypeaheadPluginProps<TOption extends MenuOption = MenuOption> =
  Omit<TypeaheadMenuPluginProps<TOption>, "triggerFn" | "onSelectOption"> & {
    triggers?: string[];
  };

export const TypeaheadPlugin = <TOption extends MenuOption>({
  triggers = [],
  onQueryChange,
  menuRenderFn,
  options,
}: TypeaheadPluginProps<TOption>) => {
  const [editor] = useLexicalComposerContext();

  const onSelectOption = useCallback(
    (
      selectedOption: TOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void,
    ) => {
      editor.update(() => {
        const expressionNode = $createExpressionNode(selectedOption.key);
        if (nodeToReplace) {
          nodeToReplace.replace(expressionNode);
        }
        expressionNode.select();
        closeMenu();
      });
    },
    [editor],
  );
  const checkForNamedPropertyTrigger = useCallback(
    (text: string) => {
      const lastWord = text.split(" ").pop() || "";

      const triggered = triggers.filter(
        (trigger) => lastWord.indexOf(trigger) === 0,
      );

      const isTriggered = lastWord && triggered.length > 0;

      if (!isTriggered) return null;

      return {
        leadOffset: text.length - lastWord.length,
        matchingString: lastWord,
        replaceableString: lastWord,
      };
    },
    [triggers],
  );

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={onQueryChange}
      onSelectOption={onSelectOption}
      options={options}
      menuRenderFn={menuRenderFn}
      triggerFn={checkForNamedPropertyTrigger}
    />
  );
};
