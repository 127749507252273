import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFinTemplates } from "../data/fin-template";
import { useGetPlot, useUpdatePlot } from "../data/plots";
import { PlotFinancialForm } from "./PlotFinancialForm";
import { useToast } from "@chakra-ui/react";

export const PlotFinancials = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { id = "" } = useParams<{ id: string }>();

  const {
    plot,
    getPlot,
    loading: plotLoading,
  } = useGetPlot({ filter: { plotid: id } });
  const { data: templates } = useGetFinTemplates();

  const { updatePlot, loading: plotUpdating } = useUpdatePlot();

  const onChange = useCallback(
    async (finTemplateId: string | null) => {
      try {
        await updatePlot(plot.id, {
          finTemplateId,
        });
        await getPlot({ plotid: id });
        toast({
          title: "Financial template updated.",
          status: "success",
          duration: 1000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Financial template update error.",
          description: error instanceof Error ? error.message : `${error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [updatePlot, getPlot, id, plot, toast],
  );

  const isLoading = useMemo(
    () => plotUpdating || plotLoading,
    [plotUpdating, plotLoading],
  );

  return (
    <>
      {plot && templates && (
        <PlotFinancialForm
          plot={plot}
          templates={templates}
          onClose={() => navigate(-1)}
          onChange={onChange}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
