import { useCallback } from "react";
import { useCalculatedPlotService } from "../data";
import { Plot } from "../data/types";
import { Action, Column, Table } from "../ui/Table";
import { Header } from "../ui";
import { AsideFormProvider } from "./AsideFormProvider";
import { useNavigate } from "react-router-dom";

const fields: Column<Plot>[] = [
  "id",
  "plotName",
  {
    name: "plotPrice",
    format: (data) => (data ? `${data.code} ${data.value}` : 0),
  },
  "status",
];

const getId = (record: Plot) => `plot-${record.id}`;

export const Plots = () => {
  const navigate = useNavigate();
  const { plots, refetch } = useCalculatedPlotService();

  const displayFinancials = useCallback(
    (data: Plot) => {
      navigate(`financials/${data.id}`);
    },
    [navigate],
  );

  return (
    <>
      <Header title="Plots" />
      {!plots?.length && <h2>No records</h2>}
      {plots && (
        <Table getId={getId} fields={fields} data={plots}>
          <Action<Plot> onClick={displayFinancials}>Financials</Action>
        </Table>
      )}

      <AsideFormProvider onClose={refetch} />
    </>
  );
};
