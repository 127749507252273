import { styled as s } from "styled-components";

// @todo: review variance

export const ActionButton = s.button<{
  $variant?: "primary" | "secondary" | "tertiary";
}>`
  padding: 4px 8px;
  border: 1px solid #9acd05;
  border-radius: 4px;
  background: #9acd05;
`;
