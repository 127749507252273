import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from "@chakra-ui/react";
import { Expression } from "../data/types";
import moment from "moment";

type ExpressionVersionsProps = {
  versions: Expression[];
};

export const ExpressionVersions = ({ versions }: ExpressionVersionsProps) => {
  if (versions.length < 1) {
    return <Heading>No Versions Available</Heading>;
  }

  return (
    <Accordion allowMultiple>
      {versions.map((version) => {
        return (
          <AccordionItem key={version.versionId}>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  style={{ textTransform: "capitalize" }}
                >
                  Created On:{" "}
                  {`${moment(version.createdAt).format("Do MMM YYYY")}`}.
                  Created By {version.userName}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <div>Expression: {version.plainText}</div>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
