import { useNavigate, useParams } from "react-router-dom";
import { FinTemplateForm } from "./FinTemplateForm";
import { useCreateFinTemplate, useGetFinTemplate } from "../data/fin-template";
import { useCallback } from "react";
import { FinTemplate } from "../data";

export const FinTemplateEdit = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();

  const { data } = useGetFinTemplate(id);

  const { create } = useCreateFinTemplate({ lazy: true });

  const onCreate = useCallback(
    async (data: FinTemplate) => {
      try {
        await create({ ...data, id });
      } catch (error) {}
    },
    [create, id],
  );

  return (
    <>
      {data && (
        <FinTemplateForm
          editMode
          defaultValues={data}
          onClose={() => navigate(-1)}
          onSubmit={onCreate}
        />
      )}
    </>
  );
};
