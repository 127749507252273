import { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { Box, useFormControlProps } from "@chakra-ui/react";

export type JsonInputProps = ReactCodeMirrorProps;

export const JsonInput = ({ ...codeMirrorProps }: ReactCodeMirrorProps) => {
  const { isInvalid } = useFormControlProps({});

  return (
    <Box
      borderColor={isInvalid ? "red" : "#444444"}
      borderStyle="solid"
      borderWidth={1}
    >
      <CodeMirror {...codeMirrorProps} extensions={[json()]} />
    </Box>
  );
};
