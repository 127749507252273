import { AxiosError, AxiosInstance } from "axios";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { usePlotifyService } from "../providers/PlotifyService";
import { PurchaseRequest } from "./types";

export const usePurchaseRequest = ({ id }: { id: string }) => {
  const fetch = useCallback((client: AxiosInstance, id: string) => {
    return client.get<PurchaseRequest>(`/purchase-requests/${id}`);
  }, []);

  const { data: purchaseRequest, ...rest } = usePlotifyService<
    PurchaseRequest,
    [id: string]
  >({
    fetch,
    initialFetchArgs: [id],
  });

  return {
    purchaseRequest,
    ...rest,
  };
};

export const useUpdatePurchaseRequest = () => {
  const fetch = useCallback(
    (client: AxiosInstance, id: string, payload: Partial<PurchaseRequest>) => {
      return client.patch<PurchaseRequest>(`/purchase-requests/${id}`, payload);
    },
    [],
  );

  const {
    data: purchaseRequest,
    refetch: updatePurchaseRequest,
    ...rest
  } = usePlotifyService<
    PurchaseRequest,
    [id: string, payload: Partial<PurchaseRequest>]
  >({
    fetch,
    lazy: true,
  });

  return {
    ...rest,
    purchaseRequest,
    updatePurchaseRequest,
  };
};

export const usePurchaseRequestsService = () => {
  const fetch = useCallback((client: AxiosInstance) => {
    return client.get<PurchaseRequest[]>("/purchase-requests");
  }, []);

  const { data, ...rest } = usePlotifyService<PurchaseRequest[]>({
    fetch,
  });

  return {
    ...rest,
    purchaseRequests: data,
  };
};

type IPADownloadResponse = { id: string; name: string; downloadURL: string };

type IPARef<T> = { id: string; meta?: T };

export type useIPAProps<T = any> = {
  onSuccess?: (downloadURL: string, ref: IPARef<T>) => void;
  onError?: (error: unknown, ref: IPARef<T>) => void;
};
export const useIPA = <T>({ onSuccess, onError }: useIPAProps<T>) => {
  const fetch = useCallback((client: AxiosInstance, id: string) => {
    return client.post<IPADownloadResponse>(
      `/purchase-requests/generate-ipa/${id}`,
    );
  }, []);

  const {
    data: ipa,
    refetch,
    error,
    ...rest
  } = usePlotifyService<IPADownloadResponse, [id: string]>({
    fetch,
    lazy: true,
  });

  const generateIPA = useCallback(
    async (ref: IPARef<T>) => {
      try {
        const {
          data: { downloadURL },
        } = await refetch(ref.id);
        onSuccess && onSuccess(downloadURL, ref);
      } catch (error) {
        if (onError && error instanceof AxiosError && error.response) {
          return onError(error.response.data, ref);
        }

        toast.error("Error Generating IPA");
        console.error("Error Generating IPA", { error, ref });
        throw error;
      }
    },
    [refetch, onError, onSuccess],
  );

  return {
    ...rest,
    error,
    ipa,
    generateIPA,
  };
};
