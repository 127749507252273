import { HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Td,
} from "@chakra-ui/react";

import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { useRecord } from "./RecordProvider";

export type RegisteredAction<T> = {
  id: string;
  label: ReactNode;
  onClick: (data: T) => void;
};

export type RegisterAction<T> = Omit<RegisteredAction<T>, "id">;

export type ActionsContextValues<T = any> = {
  registerAction: (action: RegisterAction<T>) => () => void;
  actions: RegisteredAction<T>[];
};

export const ActionsContext = createContext<ActionsContextValues>({
  registerAction: () => () => undefined,
  actions: [],
});

export const ActionsProvider = <T,>({ children }: { children?: ReactNode }) => {
  const { data } = useRecord<T>();
  const actionsRef = useRef<RegisteredAction<T>[]>([]);
  const [actions, setActions] = useState<RegisteredAction<T>[]>([]);

  const registerAction = useCallback(
    ({ label, onClick }: RegisterAction<T>) => {
      const action = { id: uuid(), label, onClick };
      actionsRef.current = [...actionsRef.current, action];
      setActions([...actionsRef.current]);

      return () => {
        actionsRef.current = actionsRef.current.filter((a) => a !== action);
        setActions([...actionsRef.current]);
      };
    },
    [],
  );

  const context = useMemo<ActionsContextValues<T>>(
    () => ({
      registerAction,
      actions,
    }),
    [actions, registerAction],
  );

  if (!children) return null;

  return (
    <ActionsContext.Provider value={context}>
      <Td style={{ position: "relative" }}>
        {children}
        <Menu>
          <MenuButton as={IconButton} icon={<HamburgerIcon />} variant="link" />
          <Portal>
            <MenuList>
              {actions.map(({ id, label, onClick }) => (
                <MenuItem key={id} onClick={() => onClick(data)}>
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </ActionsContext.Provider>
  );
};

export type ActionProps<T> = {
  children?: ReactNode;
  label?: ReactNode;
  onClick: (data: T) => void;
};

export const Action = <T,>({ children, label, onClick }: ActionProps<T>) => {
  const { registerAction } = useContext(ActionsContext);

  const content = useMemo(() => {
    if (children && label) {
      console.warn(
        "Table > Action: you should use either children or label prop and not both. Children overrides label.",
      );
    }
    return children || label || null;
  }, [children, label]);

  useEffect(() => {
    return registerAction({ label: content, onClick });
  }, [registerAction, content, onClick]);

  return null;
};
