import { AxiosInstance, AxiosResponse } from "axios";
import { usePlotifyService } from "../providers/PlotifyService";
import { CreateExpression, Expression, Plot } from "./types";
import { useCallback } from "react";

export type PlotFilter = {
  plotname?: string;
  plotid?: string;
};

export const getPlot = (client: AxiosInstance, filter: PlotFilter) => {
  return client.get<Expression, AxiosResponse<Expression>, CreateExpression>(
    "/plots/calculated",
    {
      params: {
        ...filter,
        limit: 1,
      },
    },
  );
};

export type useGetPlotOptions = {
  lazy?: true;
  filter?: PlotFilter;
};

export const useGetPlot = ({ lazy, filter }: useGetPlotOptions) => {
  const { data, refetch, ...rest } = usePlotifyService<
    Plot,
    [data: PlotFilter]
  >({
    fetch: getPlot,
    lazy,
    initialFetchArgs: filter ? [filter] : undefined,
  });

  return {
    ...rest,
    plot: Array.isArray(data) && data.length > 0 ? data[0] : undefined,
    getPlot: refetch,
  };
};

export const useUpdatePlot = () => {
  const updatePlot = useCallback(
    (
      client: AxiosInstance,
      id: string,
      data: { finTemplateId?: string | null },
    ) => {
      return client.patch(`/plots/${id}`, data);
    },
    [],
  );
  const { refetch, ...rest } = usePlotifyService<
    Plot,
    [id: string, data: { finTemplateId?: string | null }]
  >({
    fetch: updatePlot,
    lazy: true,
  });

  return {
    ...rest,
    updatePlot: refetch,
  };
};
