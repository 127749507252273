import { Drawer, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { ReactNode } from "react";

export type SideDrawerProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};
export const SideDrawer = ({ isOpen, onClose, children }: SideDrawerProps) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};
