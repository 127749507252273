import { PlusSquareIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useGetExpressions } from "../data";
import { Expression } from "../data/types";
import { Header } from "../ui/Header";
import { Action, Column, Table } from "../ui/Table";
import { AsideFormProvider, useAsideFormContext } from "./AsideFormProvider";
import { ExpressionListContext } from "../providers/ExpressionContext";

export type ExpressionActionsProps = {
  expression: Expression;
};

export const ExpressionActions = ({ expression }: ExpressionActionsProps) => {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate(`/expressions/edit/${expression.id}`)}>
      Edit
    </button>
  );
};

const getId = (e: Expression) => e.id;

const fields: Column<Expression>[] = [
  { name: "name", title: "Name" },
  { name: "description", title: "Description" },
  { name: "createdAt", title: "Last Modified" },
  { name: "versions", format: (versions) => versions?.length },
];

export const Expressions = () => {
  const { data: expressions, refetch } = useGetExpressions();
  const navigate = useNavigate();

  const openEditor = useCallback(
    (data: Expression) => {
      navigate(`edit/${data.id}`);
    },
    [navigate],
  );

  const openCreate = useCallback(() => {
    navigate("create");
  }, [navigate]);

  return (
    <>
      <ExpressionListContext.Provider value={expressions}>
        <Header title="Expressions">
          <Button
            colorScheme="green"
            leftIcon={<PlusSquareIcon />}
            onClick={openCreate}
            title="Create new expression"
          >
            Create
          </Button>
        </Header>
        {expressions && (
          <Table getId={getId} fields={fields} data={expressions}>
            <Action<Expression> onClick={openEditor}>Edit</Action>
          </Table>
        )}

        <AsideFormProvider onClose={refetch} />
      </ExpressionListContext.Provider>
    </>
  );
};

export const useExpressionNavigation = () => {
  const navigate = useNavigate();
  const props = useAsideFormContext();
  return {
    ...props,
    gotToEdit: (id: string) => {
      navigate(`../edit/${id}`, { replace: true });
    },
  };
};
