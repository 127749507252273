import { useNavigate } from "react-router-dom";
import { FinTemplateForm } from "./FinTemplateForm";
import { useCreateFinTemplate } from "../data/fin-template";
import { useCallback } from "react";
import { FinTemplate } from "../data";

export const FinTemplateCreate = () => {
  const navigate = useNavigate();

  const { create } = useCreateFinTemplate({ lazy: true });

  const onCreate = useCallback(
    async (data: FinTemplate) => {
      try {
        const {
          data: { id },
        } = await create(data);
        navigate(`../${id}`, { replace: true });
      } catch (error) {}
    },
    [navigate, create],
  );

  return <FinTemplateForm onClose={() => navigate(-1)} onSubmit={onCreate} />;
};
