import { Heading } from "@chakra-ui/react";
import { ReactNode } from "react";
import { styled as s } from "styled-components";

const Panel = s.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px 0;
`;

const ActionContainer = s.div`
`;

export type HeaderProps = {
  title: string;
  children?: ReactNode;
};

export const Header = ({ title, children }: HeaderProps) => {
  return (
    <Panel>
      <Heading>{title}</Heading>
      {children && <ActionContainer>{children}</ActionContainer>}
    </Panel>
  );
};
