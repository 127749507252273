const CurrencyFormatters: { [x: string]: Intl.NumberFormat } = {
  ...["USD", "EUR", "GBP"].reduce(
    (map, currency) => ({
      ...map,
      [currency]: Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency,
      }),
    }),
    {},
  ),
  DEFAULT: Intl.NumberFormat(navigator.language),
};

export const formatPrice = (price: number, currency?: string) => {
  const formatter =
    currency && CurrencyFormatters[currency]
      ? CurrencyFormatters[currency]
      : CurrencyFormatters.DEFAULT;
  return formatter.format(price);
};
