import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { json } from "@codemirror/lang-json";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  PurchaseRequest,
  usePurchaseRequest,
  useUpdatePurchaseRequest,
} from "../data";
import { JsonInput } from "../ui/forms/JsonInput";

export type PurchaseRequestFormProps = {
  id: string;
  onClose?: () => void;
};

export const PurchaseRequestForm = ({
  id,
  onClose,
}: PurchaseRequestFormProps) => {
  const { purchaseRequest, loading, error } = usePurchaseRequest({ id });

  const { updatePurchaseRequest } = useUpdatePurchaseRequest();

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const values = useMemo(() => {
    if (!purchaseRequest) return;
    const { meta, ...rest } = purchaseRequest;
    let formattedMeta = meta;

    try {
      formattedMeta = JSON.stringify(JSON.parse(meta), null, 2);
    } catch (error) {}

    return {
      ...rest,
      meta: formattedMeta,
    };
  }, [purchaseRequest]);

  const { handleSubmit, control } = useForm<PurchaseRequest>({
    values,
  });

  const onSubmit = useCallback(
    async ({ meta }: PurchaseRequest) => {
      try {
        await updatePurchaseRequest(id, { meta: JSON.parse(meta) });
        toast.success("Saved!");
      } catch (error) {
        // @todo: flag if json error
        toast.error(
          "Error: Purchase request data was not saved: " +
            (error as { message: string }).message,
        );
      }
    },
    [id, updatePurchaseRequest],
  );

  return (
    <Flex p="8" direction="column">
      <Flex justifyContent="space-between" alignItems="center">
        {purchaseRequest && (
          <Heading size="md">
            Edit Purchase Request: {purchaseRequest?.plotName}
          </Heading>
        )}
        <IconButton
          onClick={onClose}
          icon={<CloseIcon />}
          aria-label="close form"
          variant="ghost"
        />
      </Flex>

      {loading && <h2>Loading ...</h2>}
      {error && <h2>Error: {error.message}</h2>}
      {purchaseRequest && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="meta"
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl mb="4">
                  <FormLabel>Purchase Request Meta:</FormLabel>
                  <JsonInput
                    value={value}
                    height="200px"
                    extensions={[json()]}
                    onChange={onChange}
                  />
                </FormControl>
              );
            }}
          />

          <Flex>
            <Button type="submit" colorScheme="green">
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Flex>
  );
};
