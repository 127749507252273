import { CloseIcon } from "@chakra-ui/icons";
import { Flex, FlexProps, Heading, IconButton } from "@chakra-ui/react";
import { FormEventHandler, ForwardedRef, ReactNode, forwardRef } from "react";
import { styled as s } from "styled-components";

const Form = s.form`
  margin: 0;
  padding: 0;
`;
export type FormContainerProps = FlexProps & {
  title: string;
  onSubmit?: FormEventHandler;
  onClose?: () => void;
  children: ReactNode;
};

export const FormContainer = forwardRef(
  (
    { title, onClose, onSubmit, children, ...flexProps }: FormContainerProps,
    ref: ForwardedRef<HTMLFormElement>,
  ) => {
    return (
      <Flex p="8" direction="column" {...flexProps}>
        <Flex mb="20px" justifyContent="space-between" alignItems="center">
          {title && <Heading size="md">{title}</Heading>}
          {onClose && (
            <IconButton
              onClick={onClose}
              icon={<CloseIcon />}
              aria-label="close form"
              variant="ghost"
            />
          )}
        </Flex>
        <Form ref={ref} onSubmit={onSubmit}>
          {children}
        </Form>
      </Flex>
    );
  },
);
