import { PlusSquareIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { Action, Column, Header, Table } from "../ui";
import { AsideFormProvider } from "./AsideFormProvider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FinTemplate } from "../data";
import { useGetFinTemplates } from "../data/fin-template";

const fields: Column<FinTemplate>[] = [
  { name: "name", title: "Name" },
  { name: "createdAt", title: "Created" },
  { name: "versions", format: (versions) => versions?.length },
];
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const getId = (e: FinTemplate) => e.id!;

export const FinTemplates = () => {
  const navigate = useNavigate();

  const openCreate = useCallback(() => {
    navigate("create");
  }, [navigate]);

  const openEditor = useCallback(
    (data: FinTemplate) => {
      navigate(`${data.id}`);
    },
    [navigate],
  );

  const { data, refetch } = useGetFinTemplates();

  return (
    <>
      <Header title="Financial Template">
        <Button
          colorScheme="green"
          leftIcon={<PlusSquareIcon />}
          onClick={openCreate}
          title="Create new template"
        >
          Create
        </Button>
      </Header>
      {data && (
        <Table getId={getId} fields={fields} data={data}>
          <Action<FinTemplate> onClick={openEditor}>Edit</Action>
        </Table>
      )}
      <AsideFormProvider onClose={refetch} />
    </>
  );
};
