import { MenuOption } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { styled as s } from "styled-components";

const Option = s.div<{ $isHighlighted?: boolean }>`
  background-color: ${({ $isHighlighted }) => ($isHighlighted ? "rgba(0,0,0,0.1)" : "none")};
  padding: 8px 12px;
`;

const MenuContainer = s.div`
  border-radius: 4px;
  background: white;
  position: absolute;
  box-shadow: rgba(0,0,0,0.1) 0px 0px 12px;
  z-index: 1500;
`;

export type MenuOptionsListProps = {
  options: MenuOption[];
  selectedIndex?: number | null;
  onClick: (selectedIndex: number) => void;
  onHighlight: (selectedIndex: number) => void;
};
export const MenuOptionsList = ({
  options,
  onClick,
  onHighlight,
  selectedIndex,
}: MenuOptionsListProps) => {
  return (
    <MenuContainer>
      {options.map((o, index) => (
        <Option
          onClick={() => onClick(index)}
          key={o.key}
          onMouseEnter={() => onHighlight(index)}
          $isHighlighted={index === selectedIndex}
        >
          {o.key}
        </Option>
      ))}
    </MenuContainer>
  );
};
