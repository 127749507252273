import { AxiosInstance } from "axios";
import { usePlotifyService } from "../providers/PlotifyService";
import { Plot } from "./types";

const fetch = (client: AxiosInstance) => {
  return client.get("/plots");
};

export const usePlotService = () => {
  const { data: plots, ...rest } = usePlotifyService<Plot[]>({
    fetch,
    refetchOnClientChange: true,
  });

  return { ...rest, plots };
};

const fetchCalculated = (client: AxiosInstance) => {
  return client.get("/plots/calculated");
};

export const useCalculatedPlotService = () => {
  const { data: plots, ...rest } = usePlotifyService<Plot[]>({
    fetch: fetchCalculated,
    refetchOnClientChange: true,
  });

  return { ...rest, plots };
};
