import {
  TableContainer,
  Box,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Table as UITable,
} from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";
import { isColumnConfig } from "../../utils";
import { ActionsProvider } from "./ActionsProvider";
import { RecordProvider } from "./RecordProvider";
import { styled as s } from "styled-components";

type Property<T> = Extract<keyof T, string>;
type Optional<T> = T | undefined | null;

type ColumnMap<T> = {
  [K in Property<T>]: {
    title?: string;
    name: K;
    format?: (value: Optional<T[K]>, data: T) => ReactNode;
  };
};

export type CustomColumnConfig<T> = {
  title?: string;
  render: (data: T) => ReactNode;
};

export type ColumnConfig<T> = ColumnMap<T>[keyof ColumnMap<T>];

export type Column<T> = Property<T> | ColumnConfig<T> | CustomColumnConfig<T>;

type InnerColumnConfig<T> = ColumnConfig<T> | CustomColumnConfig<T>;

type HeaderProps = {
  title?: string;
};

const Header = ({ title }: HeaderProps) => {
  return <Th>{title}</Th>;
};

const BlankHeader = () => <Th />;

type CellProps<T> = {
  field: InnerColumnConfig<T>;
  data: T;
};

const Cell = <T,>({ field, data }: CellProps<T>) => {
  if (isColumnConfig<T>(field)) {
    const value = data[field.name];
    const formatted = field.format
      ? field.format(value, data)
      : value
        ? String(value)
        : "";
    return (
      <Td maxWidth={"250px"} whiteSpace={"break-spaces"}>
        {formatted}
      </Td>
    );
  }

  return <Td>{field.render(data)}</Td>;
};

type RecordProps<DataType> = {
  fields: InnerColumnConfig<DataType>[];
  data: DataType;
  getId?: (record: DataType) => string;
  children: ReactNode;
};

const Record = <DataType,>({
  fields,
  data,
  getId,
  children: editActions,
}: RecordProps<DataType>) => {
  const getKey = getId
    ? (record: DataType, index: number) => `${getId(record)}-${index}`
    : (_: DataType, index: number) => `cell-${index}`;

  return (
    <RecordProvider<DataType> data={data}>
      <Tr>
        {fields.map((field, index) => (
          <Cell key={getKey(data, index)} field={field} data={data} />
        ))}
        <ActionsProvider>{editActions}</ActionsProvider>
      </Tr>
    </RecordProvider>
  );
};

export type TableProps<DataType> = {
  data: DataType[];
  fields?: Column<DataType>[];
  getId?: (record: DataType) => string;
  children?: ReactNode;
};

export const Table = <DataType extends Record<string, unknown>>({
  fields,
  data,
  getId,
  children: editActions,
}: TableProps<DataType>) => {
  const getKey = getId
    ? getId
    : (_: DataType, index: number) => `record-${index}`;

  const headers = useMemo<InnerColumnConfig<DataType>[]>(() => {
    if (fields) {
      return fields.map((field) => {
        if (typeof field === "string") {
          return { title: field, name: field };
        }

        if (isColumnConfig<DataType>(field)) {
          field.title = field.title || field.name;
        }

        return field;
      });
    }

    // create default column data based on data
    const columns = data
      .reduce<string[]>((headers, record) => {
        return [...headers, ...Object.keys(record)];
      }, [])
      .filter(
        (name, index, self): name is Property<DataType> =>
          typeof name === "string" && self.indexOf(name) === index,
      )
      .map<ColumnConfig<DataType>>((name) => ({ name }));
    return columns;
  }, [data, fields]);

  const hasActions = !!editActions;
  const VerticalScroll = s(Box)`
  overflow-y: auto;
`;

  return (
    <VerticalScroll>
      <TableContainer bg="white">
        <UITable variant="striped" size="sm">
          <Thead>
            <Tr>
              {headers.map((header, index) => {
                return (
                  <Header
                    key={`header-${header.title || "blank"}-${index}`}
                    title={header.title}
                  />
                );
              })}
              {hasActions && <BlankHeader />}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((record, index) => {
              return (
                <Record
                  key={getKey(record, index)}
                  fields={headers}
                  data={record}
                  getId={getId}
                >
                  {editActions}
                </Record>
              );
            })}
          </Tbody>
        </UITable>
      </TableContainer>
    </VerticalScroll>
  );
};
