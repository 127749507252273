import { useAuth0 } from "@auth0/auth0-react";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type AccessTokenContextProps = {
  devMode?: boolean;
  accessToken?: string;
};

const AccessTokenContext = createContext<AccessTokenContextProps>({});

export type AccessTokenProviderProps = {
  children: ReactNode;
};

export const AccessTokenProvider = ({ children }: AccessTokenProviderProps) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>();

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:current_user",
          },
        });
        setAccessToken(accessToken);
      } catch (error) {
        console.error(
          "cannot retrieve access token. Please logout and login. Or Contact IT.",
        );
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently]);

  // @todo: check if token is actually valid
  // redirect if it is not to a page to log them out

  const context = useMemo(
    () => ({
      accessToken,
    }),
    [accessToken],
  );

  return (
    <AccessTokenContext.Provider value={context}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export const useAccessToken = () => {
  return useContext(AccessTokenContext);
};
