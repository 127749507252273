const {
  REACT_APP_AUTH0_DOMAIN = "",
  REACT_APP_AUTH0_CLIENTID = "",
  REACT_APP_AUTH0_AUDIENCE = "",
  REACT_APP_API_BASE_URL = "",
} = process.env;

const config = {
  auth0Domain: REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: REACT_APP_AUTH0_CLIENTID,
  auth0Audience: REACT_APP_AUTH0_AUDIENCE,
  apiBaseUrl: REACT_APP_API_BASE_URL,
};

console.log({ config }, process.env);

export default config;
