import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Protected } from "./Protected";
import cfg from "./config";
import {
  ExpressionCreate,
  ExpressionEdit,
  Expressions,
  PurchaseRequests,
} from "./page";
import { AccessTokenProvider } from "./providers/AccessTokenProvider";
import { PlotifyServiceProvider } from "./providers/PlotifyService";
import { AdminLayout, NavItemProps } from "./ui/AdminLayout";
import { enableDevMode } from "./utils/misc";
import { FinTemplates } from "./page/FinTemplates";
import { FinTemplateCreate } from "./ui/FinTemplateCreate";
import { FinTemplateEdit } from "./ui/FinTemplateEdit";
import { Plots } from "./page/Plots";
import { PlotFinancials } from "./ui/PlotFinancials";

const NavItems: NavItemProps[] = [
  { name: "Plots", route: "/plots" },
  { name: "Expressions", route: "/expressions" },
  { name: "Financial Templates", route: "/fin-templates" },
  { name: "Purchase Requests", route: "/purchase-requests" },
  // { name: 'Sign Out', route: '/logged-out' },
];

function App() {
  useEffect(() => {
    enableDevMode();
  }, []);

  return (
    <Auth0Provider
      domain={cfg.auth0Domain}
      clientId={cfg.auth0ClientId}
      // useRefreshTokens
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: cfg.auth0Audience,
        scope: "read:current_user",
      }}
    >
      <AccessTokenProvider>
        <BrowserRouter>
          <ChakraProvider>
            <ToastContainer />
            <PlotifyServiceProvider baseURL={cfg.apiBaseUrl}>
              <div className="App">
                <AdminLayout navItems={NavItems}>
                  <Protected>
                    <Routes>
                      <Route path="/plots" element={<Plots />}>
                        {/* <Route
                          path="create"
                          element={<ExpressionCreate />}
                        /> */}
                        <Route
                          path="financials/:id"
                          element={<PlotFinancials />}
                        />
                      </Route>
                      <Route path="/expressions" element={<Expressions />}>
                        <Route path="create" element={<ExpressionCreate />} />
                        <Route path="edit/:id" element={<ExpressionEdit />} />
                      </Route>
                      <Route
                        path="/purchase-requests"
                        element={<PurchaseRequests />}
                      />
                      <Route path="/fin-templates" element={<FinTemplates />}>
                        <Route path="create" element={<FinTemplateCreate />} />
                        <Route path=":id" element={<FinTemplateEdit />} />
                      </Route>
                    </Routes>
                  </Protected>
                </AdminLayout>
              </div>
            </PlotifyServiceProvider>
          </ChakraProvider>
        </BrowserRouter>
      </AccessTokenProvider>
    </Auth0Provider>
  );
}

export default App;
