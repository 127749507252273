type IsNotObjectLiteral = (value: unknown) => boolean;

export type CustomIsNotObjectLiteral =
  | IsNotObjectLiteral
  | IsNotObjectLiteral[];
export const isObjectLiteral = (
  value: unknown,
  additionalChecks?: CustomIsNotObjectLiteral,
  // eslint-disable-next-line @typescript-eslint/ban-types
): value is Object => {
  if (typeof value !== "object") return false;

  if (Array.isArray(value)) return false;

  if (value === null) return false;

  if (value instanceof Date) return false;

  if (additionalChecks) {
    const checks = Array.isArray(additionalChecks)
      ? additionalChecks
      : [additionalChecks];

    return !checks.find((check) => check(value));
  }

  return true;
};
