import { ZodIssue } from "zod";
import { camelCase } from "change-case";
import { styled as s } from "styled-components";

const IssueTitle = s.div`
  color: #444;
  font-size: 20px;
  font-weight: bold;
`;
const Issue = s.div`
  font-size: 16px;
  margin-top: 8px;
  line-height: 1.3;
  color: #444;

  span {
    opacity: 0.5;
  }
`;

export type ValidationError = {
  issues: ZodIssue[];
};

export const isValidationError = (error: unknown): error is ValidationError => {
  return !!(error && Array.isArray((error as { issues: any }).issues));
};

export type ToastZodIssuesProps = {
  title: string;
  error: ValidationError;
};
export const ValidationErrors = ({ title, error }: ToastZodIssuesProps) => {
  return (
    <>
      <IssueTitle>{title}</IssueTitle>
      {error.issues.map((issue) => {
        const path = issue.path.map((str) => camelCase(`${str}`)).join("/");
        const context =
          issue.code === "invalid_type"
            ? `(current value is ${issue.received})`
            : "";
        return (
          <Issue>
            <b>{`${path}: `}</b>
            {issue.message.toLowerCase()} <span>{context}</span>
          </Issue>
        );
      })}
    </>
  );
};
