import { ReactNode } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { styled as s } from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const Container = s.div`
  display: flex;
  
`;

const Aside = s.div`
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e1e1e1;
`;

const Main = s.div`
  flex: 1;
  height: 100vh;
  overflow: scroll;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 20px;
  background: #f6f6f6;
`;

const AsideFooter = s.div`
  border-top: 1px solid #ebebeb;
  padding: 8px;
`;

const AsideMain = s.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const LinkButton = s.a<{ $isActive?: boolean }>`
  color: green;
  margin: 8px 0;
  padding: 8px;
  text-decoration: none;
  border-radius: 8px;
`;

export type NavItemProps = { name: string; route: string };

export const NavItem = ({ name, route }: NavItemProps) => {
  const navigate = useNavigate();
  const match = useMatch({ path: route });

  const isActive = Boolean(match);

  return (
    <LinkButton
      $isActive={isActive}
      href={route}
      onClick={(e) => {
        e.preventDefault();
        navigate(route);
      }}
    >
      {name}
    </LinkButton>
  );
};

export type AdminLayoutProps = {
  children: ReactNode;
  navItems?: NavItemProps[];
};

export const AdminLayout = ({ children, navItems = [] }: AdminLayoutProps) => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <Container>
      {isAuthenticated && (
        <Aside>
          {/* <AsideHeader></AsideHeader> */}
          <AsideMain>
            {navItems.map((props) => {
              return <NavItem key={`nav-${props.route}`} {...props} />;
            })}
          </AsideMain>
          <AsideFooter>
            <button onClick={() => logout()}>Sign Out</button>
          </AsideFooter>
        </Aside>
      )}
      <Main>{children}</Main>
    </Container>
  );
};
