import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect } from "react";

export type ProtectedProps = {
  children: ReactNode;
};

export const Protected = ({ children }: ProtectedProps) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <>Loading authentication</>;
  }

  return <>{isAuthenticated && children}</>;
};
