import { Plot } from "../types";
// import {}
export const generatePlotData = (): Plot =>
  ({
    id: "315a8faa-2a4f-4e04-833c-b453d0b55d91",
    createdAt: "2020-05-02T15:13:58.338Z",
    updatedAt: "2023-02-27T11:01:51.424Z",
    disabledAt: null,
    deletedAt: null,
    storageId: "2991ad3d-97fa-4d03-9c03-16799ffad42d",
    propertyId: "4821a3a0-adb0-4b47-9a28-5c0374a4a686",
    loanId: "1b734d88-40cb-48df-989a-a6e460c4749c",
    propertyManagementId: null,
    containerizationStatusId: null,
    incorporationCountryId: null,
    financialDetailsId: null,
    isListed: false,
    listedSince: null,
    lastSale: "2021-05-17T15:10:27.350Z",
    assetName: "PLOT-USA-HS-00019 LLC",
    plotName: "MOCK-USA-HS-00001",
    companyRegistrationId: null,
    taxId: " 84-4716602",
    currentLegalOwnership: null,
    address: {
      country: {
        id: "ef9347f8-9dcd-4a45-b946-034d063c7856",
        name: "United States",
        isoCode: "USA",
      },
      premise: null,
      postalCode: "98033",
      localityName: "Kirkland",
      thoroughfare: "714 3rd Street South",
      administrativeArea: {
        id: "0df583ea-5a2c-4c64-b26a-44bad0b0503e",
        code: "WA",
        name: "Washington",
      },
      dependentLocalityName: "USA",
    },
    propertyPrice: {
      code: "USD",
      value: 82000,
    },
    plotPrice: {
      code: "USD",
      value: 82000,
    },
    originalListedPropertyOfferPrice: {
      code: "USD",
      value: 82000,
    },
    buyerTrxFeePercent: 0.01,
    sellerTrxFeePercent: 0,
    totalReturnPercent: 0.807,
    status: "ownedByInvestor",
    plotType: "exclusive",
    marketPartnerId: null,
    expectedCloseDate: "2021-05-16T23:00:00.000Z",
    remainingDaysToCloseDate: -358,
    envelopeForListing: null,
    ownershipDate: null,
    settlementDate: null,
    provider: null,
    adminTimezone: null,
    incorporationDate: null,
    operatingDate: null,
    isFeatured: false,
    appreciationProfit: null,
    leadImage: null,
    incomeexpenses: {
      id: "5f6628f9-0942-4e8d-bb3a-a128f2fd375a",
      rent: {
        code: "USD",
        value: 13200,
      },
      plotId: "315a8faa-2a4f-4e04-833c-b453d0b55d91",
      coverages: {
        code: "USD",
        value: 3337,
      },
      createdAt: "2020-05-02T15:14:24.316+00:00",
      deletedAt: null,
      insurance: {
        code: "USD",
        value: 423,
      },
      legalFees: {
        code: "USD",
        value: 0,
      },
      updatedAt: "2022-05-09T13:29:00.536+00:00",
      disabledAt: null,
      leasingFees: {
        code: "USD",
        value: 0,
      },
      loanPayment: {
        code: "USD",
        value: 2460,
      },
      propertyTax: {
        code: "USD",
        value: 1104,
      },
      carvCoverage: {
        code: "USD",
        value: 3337,
      },
      totalIncomes: {
        code: "USD",
        value: 13200,
      },
      accountingFee: {
        code: "USD",
        value: 488,
      },
      debtServicing: {
        code: "USD",
        value: 2460,
      },
      loanServicing: {
        code: "USD",
        value: 0,
      },
      plotServicing: {
        code: "USD",
        value: 964,
      },
      totalExpenses: {
        code: "USD",
        value: 9476,
      },
      propertyExpenses: {
        code: "USD",
        value: 2715,
      },
      contentsInsurance: {
        code: "USD",
        value: 0,
      },
      assetManagementFee: {
        code: "USD",
        value: 410,
      },
      propertyGroundRent: {
        code: "USD",
        value: 0,
      },
      propertyManagement: {
        code: "USD",
        value: 1188,
      },
      propertyServiceCharge: {
        code: "USD",
        value: 0,
      },
      rentAndLegalInsurance: {
        code: "USD",
        value: 0,
      },
      nomineeAssetManagement: {
        code: "USD",
        value: 66,
      },
      bankingAndLedgeringFees: {
        code: "USD",
        value: 0,
      },
      officerAndDirectorInsurance: {
        code: "USD",
        value: 0,
      },
      corpGovernanceAndAnnualCorpFees: null,
    },
    loan: {
      id: "1b734d88-40cb-48df-989a-a6e460c4749c",
      loanId: "1111",
      plotId: "315a8faa-2a4f-4e04-833c-b453d0b55d91",
      provider: null,
      createdAt: "2020-05-02T15:14:18.628+00:00",
      deletedAt: null,
      updatedAt: "2022-05-09T13:29:00.068+00:00",
      disabledAt: null,
      loanAmount: null,
      initialDraw: null,
      construction: null,
      interestOnly: true,
      interestRate: 0.05,
      paymentAmount: {
        code: "USD",
        value: 205,
      },
      outstandingBalance: {
        code: "USD",
        value: 49200,
      },
      paymentFrequencyId: "92309378-40e0-46a6-897f-6b0f789edac0",
      outstandingInterestBalance: null,
    },
    financials: {
      id: "606fd275-6b42-47a3-b64c-958339524cfe",
      ltv: 7614604,
      capex: 6797742,
      plotId: "0d1a434e-8d88-45f4-9fd4-f4d5aa2de468",
      carvper: 543927,
      createdAt: "2024-01-22T12:50:22.374+00:00",
      updatedAt: "2024-01-22T12:50:22.374+00:00",
      holdPeriod: 7638265,
      appraisalFee: 2878124,
      inspectionFee: 3044225,
      numberofUnits: 5522543,
      renewalFeeper: 9103857,
      averageTenancy: 675472,
      newLeasefeeper: 9553235,
      closingCostsper: 817519,
      renewalFeefixed: 9254958,
      acquisitionPrice: 542064,
      newLeasefeefixed: 7479335,
      llcFormationcosts: 1406736,
      aqusitionSpreadper: 1515193,
      assetManagementfee: 9689418,
      registeredAgentfee: 8830625,
      propertyManagementper: 3732963,
      propertyManagementfixed: 5159549,
    },
    propertymanager: {
      id: "d4971cf5-e0b7-48e9-822d-82cefa596f76",
      fee: null,
      createdAt: "2019-09-10T17:05:55.122+00:00",
      deletedAt: null,
      partnerId: "eb99e08d-cf03-45dc-a90d-73822c022b6f",
      updatedAt: "2019-09-10T17:05:55.122+00:00",
      disabledAt: null,
    },
    partner: {
      id: "eb99e08d-cf03-45dc-a90d-73822c022b6f",
      name: "Rentwell",
      note: "Property Management Fee: 9%\nOne Time Account Set Up Service: $150\nLease Renewal Service Fee: $100\nStd Eviction Fee: $500\nOnsite Services: $75 first hour ($35 additional)\nTechnology & Convenience Reimbursement: $5 per unit/per month\nRepair limit without approval: $250\nUnit Reserve: $500\n---------------------------------------\n10% markup off of vendor invoices\n\n",
      email: "sfriedel@rentwell.com",
      typeId: "60f7315e-f97e-4f8b-932c-4e6f0ee8781a",
      address: {
        country: {
          id: "ef9347f8-9dcd-4a45-b946-034d063c7856",
          name: "United States",
          isoCode: "USA",
        },
        premise: null,
        postalCode: "PA 15229",
        localityName: "Pittsburgh",
        thoroughfare: "100 CENTER AVENUE WEST VIEW, PA 15229",
        administrativeArea: {
          id: "a8b05664-e220-4852-a97e-c9a2a08d6979",
          code: "PA",
          name: "Pennsylvania",
        },
        dependentLocalityName: null,
      },
      website: "rentwell.com",
      createdAt: "2019-09-09T14:34:46.003+00:00",
      deletedAt: null,
      updatedAt: "2019-09-09T15:13:10.228+00:00",
      disabledAt: null,
      isAvailable: true,
      phoneNumber: "412.415.3964.",
      contactPersonEmail: "sfriedel@rentwell.com",
      contactPersonFullName: "Susan Friedel",
      contactPersonPhoneNumber: "412.228.5816",
    },
  }) as Plot;
