import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { compile } from "@plotify-ops/shared/dist/expressions";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { styled as s } from "styled-components";
import { Plot, useGetExpressions } from "../data";
import { flatObject } from "../utils/flat-object";

type ExpressionPreviewProps = {
  name: string;
  expression: string;
  plot: Plot;
  onRequestPlotData: (name: string) => void;
  isFetchingPlot?: boolean;
  onResetPlotData: () => void;
};
export const ExpressionPreview = ({
  name,
  expression,
  plot,
  onRequestPlotData,
  isFetchingPlot,
  onResetPlotData,
}: ExpressionPreviewProps) => {
  const [plotName, setPlotName] = useState("");

  const { data: expressions } = useGetExpressions();

  // compile
  const calculated = useMemo(() => {
    const fields = (expressions || []).reduce<{ [x: string]: string }>(
      (collection, expression) => {
        return {
          ...collection,
          [expression.name]: expression.plainText,
        };
      },
      {},
    );

    fields[name] = expression;

    try {
      const compiled = compile(fields);
      return compiled.evaluate({ p: plot });
    } catch (error) {
      // @todo handle unknown cases
      console.error(error);
      return {};
    }
  }, [name, expression, expressions, plot]);

  const plotFlatData = useMemo(() => {
    return flatObject(plot) as Record<string, unknown>;
  }, [plot]);

  const fetchPlotData = useCallback(() => {
    onRequestPlotData(plotName);
  }, [onRequestPlotData, plotName]);

  const resetPlotData = useCallback(() => {
    onResetPlotData();
    setPlotName("");
  }, [setPlotName, onResetPlotData]);

  return (
    <Stack
      spacing="4"
      direction="column"
      justifyContent="stretch"
      height="100%"
    >
      <Stack direction="row" spacing="4" alignItems="center">
        <Text whiteSpace="nowrap">Plot name:</Text>
        <Input
          value={plotName}
          disabled={isFetchingPlot}
          onChange={(e) => setPlotName(e.target.value)}
          width="auto"
          placeholder="Enter plot name ..."
        />
        <Button
          disabled={isFetchingPlot}
          colorScheme="blue"
          onClick={fetchPlotData}
        >
          {isFetchingPlot ? "Loading ..." : "Update Preview"}
        </Button>

        <Button
          disabled={isFetchingPlot}
          onClick={resetPlotData}
          variant="outline"
        >
          Reset
        </Button>
        {/* reset to generated data */}
      </Stack>
      <Divider />
      <Grid
        flex={1}
        width="100%"
        templateRows="minmax(0, 100%)"
        templateColumns="1fr 1fr"
        gap="32px"
      >
        <GridItem rowSpan={1} colSpan={1}>
          <PropertyTable title="Calculated" data={calculated}>
            List of calculated data
          </PropertyTable>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PropertyTable title="Plot Data" data={plotFlatData}>
            Plot: {plot.plotName}
          </PropertyTable>
        </GridItem>
      </Grid>
    </Stack>
  );
};

type PropertyTableProps = {
  title: string;
  data: Record<string, unknown>;
  children?: ReactNode;
  onChange?: (value: any) => void;
};

const VerticalScroll = s(Box)`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
`;

export const PropertyTable = ({
  children: description,
  title,
  data,
}: PropertyTableProps) => {
  return (
    <Stack flexDirection="column" height="100%" spacing={4}>
      <Heading as="h2" mb="12px">
        {title}
      </Heading>
      {description && <Text fontSize="sm">{description}</Text>}
      <Divider />
      <VerticalScroll>
        <Table
          variant="striped"
          w="100%"
          size="sm"
          __css={{ "table-layout": "fixed" }}
        >
          <Thead position="sticky" top={0} bg="white">
            <Tr>
              <Th pl={4}>Name</Th>
              <Th pr={4} textAlign={"right"}>
                Data
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(data).map(([key, value]) => {
              return (
                <Tr key={key} whiteSpace={"normal"} wordBreak={"break-word"}>
                  <Td pl={4} fontWeight={"bold"}>
                    {key}
                  </Td>
                  <Td pr={4} textAlign={"right"}>{`${value}`}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </VerticalScroll>
    </Stack>
  );
};
