"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editorStateToPlainText = void 0;
const lexical_1 = require("lexical");
const editor_node_1 = require("./editor.node");
const editorConfig = {
    namespace: 'Editor',
    theme: {
        ltr: 'ltr',
        rtl: 'rtl',
        placeholder: 'editor-placeholder',
        paragraph: 'editor-paragraph',
    },
    onError(error) {
        throw error;
    },
    nodes: [editor_node_1.ExpressionNode],
};
const editorStateToPlainText = (expression) => {
    try {
        const editor = (0, lexical_1.createEditor)(editorConfig);
        const parsedState = editor.parseEditorState(expression);
        const plainText = parsedState.read(() => (0, lexical_1.$getRoot)().getTextContent());
        return plainText;
    }
    catch (error) {
        // @todo: log properly
        // console.error(`Error parsing expression as lexical content: `, expression);
        return expression;
    }
};
exports.editorStateToPlainText = editorStateToPlainText;
