import { AxiosInstance, AxiosResponse } from "axios";
import { usePlotifyService } from "../providers/PlotifyService";
import { UpdateExpression, CreateExpression, Expression } from "./types";
import { useCallback, useState } from "react";
import dummyPlot from "./dummy-plot.json";

export const createExpression = (
  client: AxiosInstance,
  data: CreateExpression,
) => {
  return client.post<Expression, AxiosResponse<Expression>, CreateExpression>(
    "/expressions",
    data,
  );
};

export const useExpressionCreateService = () => {
  const { loading, error, refetch } = usePlotifyService<
    Expression,
    [data: CreateExpression]
  >({
    fetch: createExpression,
    lazy: true,
  });

  return {
    loading,
    error,
    createExpression: refetch,
  };
};

export const useExpressionUpdate = () => {
  const fetch = useCallback(
    (client: AxiosInstance, id: string, payload: UpdateExpression) => {
      return client.put<Expression>(`/expressions/${id}`, payload);
    },
    [],
  );

  const {
    data: expression,
    refetch: updateExpression,
    ...rest
  } = usePlotifyService<Expression, [id: string, payload: UpdateExpression]>({
    fetch,
    lazy: true,
  });

  return {
    ...rest,
    expression,
    updateExpression,
  };
};

export type useExpressionResponse = Omit<
  ReturnType<typeof usePlotifyService>,
  "data "
> & {
  expression: Expression;
};

export const useExpression = ({ id }: { id: string }) => {
  const getExpression = useCallback((client: AxiosInstance, id: string) => {
    return client.get<Expression>(`/expressions/${id}`);
  }, []);

  const { data, ...rest } = usePlotifyService<Expression, [id: string]>({
    fetch: getExpression,
    initialFetchArgs: [id],
  });

  return {
    expression: data,
    ...rest,
  };
};

export const getExpressionVersions = (client: AxiosInstance, id: string) => {
  return client.get<Expression[]>(`/expressions/${id}/versions`);
};

type useGetExpressionVersionsOptions = {
  id?: string;
  lazy?: boolean;
};

export const useGetExpressionVersions = ({
  id,
  lazy,
}: useGetExpressionVersionsOptions) => {
  const { data, refetch, ...rest } = usePlotifyService<
    Expression[],
    [id: string]
  >({
    fetch: getExpressionVersions,
    lazy,
    initialFetchArgs: id ? [id] : undefined,
  });

  return {
    ...rest,
    expressionVersions: data,
    getExpressionVersions: refetch,
  };
};

export type useGetExpressionsOptions = {
  lazy: boolean;
};

export const useGetExpressions = (options?: useGetExpressionsOptions) => {
  const { lazy } = options || {};

  const fetch = useCallback(async (client: AxiosInstance) => {
    return client.get<Expression[]>("/expressions");
  }, []);

  return usePlotifyService<Expression[]>({
    lazy,
    fetch,
  });
};

export type useGetExpressionParamsOptions = {
  query?: string | null;
};
export const useGetExpressionParams = ({}: useGetExpressionParamsOptions) => {
  const [data, setData] = useState<typeof dummyPlot | null>(null);

  const fetch = useCallback(() => {
    setData(dummyPlot);
  }, []);

  return {
    data,
    fetch,
  };
};

export const useGetPlotDataPoints = () => {
  const fetch = useCallback((client: AxiosInstance) => {
    return client.get<string[]>(`/data/data-points`);
  }, []);

  return usePlotifyService<string[]>({
    fetch,
  });
};
